import { ComponentTamdaocHomePage } from "@/gql/generated-types"
import { Box, Flex, Grid, MantineTheme, Text, createStyles, getStylesRef } from "@mantine/core"
import { first } from "lodash"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { FC } from "react"

const useStyles = createStyles((theme: MantineTheme) => ({
  firstNews: {
    position: "relative",
    // height: ,
    "& a": {
      height: 450,
    },
    "& > a > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 4,
      maxHeight: 920,
    },
    [theme.fn.smallerThan("sm")]: {
      height: "auto",
      width: "100%",
      "& a": {
        height: "auto",
      },
    },
  },
  link: {
    position: "relative",
    width: "100%",
    display: "flex",
    height: "100%",
    borderRadius: 4,
    "&:before": {
      // display: "none",
      content: '""',
      top: "0",
      position: "absolute",
      left: "0",
      width: "100%",
      height: "100%",
      // background: "linear-gradient(0deg,rgba(0,0,0,.7),transparent)",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0.3) 74.48%)",
      borderRadius: 4,
      // zIndex: -1,
    },
    "&:hover": {
      filter: "brightness(1)",
      [`& .${getStylesRef("hoverSection")}`]: {
        opacity: 1,
      },
    },
  },
  img: {
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 4,
    },
  },
  rightSection: {
    "&:after": {
      display: "none",
    },
  },
  title: {
    textTransform: "uppercase",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    color: "#FFF",
    textAlign: "center",
    padding: "2rem 0",
    fontSize: "1.25rem",
    lineHeight: 1.2,
  },
  detail: {
    ref: getStylesRef("hoverSection"),
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
    background: "#19B04E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 4,
    transition: "all .3s",
    opacity: 0,
  },
  detailTitle: {
    marginTop: "1.5rem",
    marginBottom: "2rem",
    color: "#FFF",
    fontSize: "1.25rem",
    lineHeigh: 1.2,
    textTransform: "uppercase",
    fontWeight: 700,
    [theme.fn.smallerThan("sm")]: {
      fontSize: "18px",
      margin: "1rem 0 .5rem",
    },
  },
  detailContent: {
    color: "rgba(255,255,255,.7)",
    fontSize: "1rem",
    lineHeight: 1.5,
    padding: "0 4rem",
    textAlign: "center",
    [theme.fn.smallerThan("sm")]: {
      fontSize: "14px",
      padding: "0 2rem",
    },
  },
  detailIcon: {
    [theme.fn.smallerThan("sm")]: {
      width: "90px",
    },
  },
  arrow: {
    marginTop: "1.5rem",
  },
}))

interface Props {
  block?: ComponentTamdaocHomePage
}

export const RentalService: FC<Props> = ({ block }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation("common")

  const services = block?.services
  const firstService = first(services)
  const secondService = services && services?.length > 1 ? services[1] : undefined
  const thirdService = services && services?.length > 2 ? services[2] : undefined

  return (
    <Flex m={0} p={0} direction={"column"}>
      <Text fz={{ base: 18, sm: 24 }} fw={700} sx={{ textTransform: "uppercase" }}>
        {t("Rental Service")}
      </Text>
      <Grid gutter={24} mt={{ base: 12 }} grow align="stretch">
        <Grid.Col className={classes.firstNews} span={12} sm={5}>
          <Link style={{ marginBottom: 20 }} href={"/rental-service"} className={classes.link}>
            <img src={firstService?.image?.data?.attributes?.url} alt="News" />
            <Text className={classes.title}>{firstService?.title}</Text>
            <Box className={classes.detail}>
              <img src={firstService?.icon?.data?.attributes?.url} className={classes.detailIcon} />
              <Text className={classes.detailTitle}>{firstService?.title}</Text>
              <Text className={classes.detailContent}>{firstService?.content}</Text>
              <img src="/images/arrow.png" className={classes.arrow} />
            </Box>
          </Link>

          <Link style={{ marginTop: 0 }} href={"/rental-service"} className={classes.link}>
            <img src={secondService?.image?.data?.attributes?.url} alt="News" />
            <Text className={classes.title}>{secondService?.title}</Text>
            <Box className={classes.detail}>
              <img src={secondService?.icon?.data?.attributes?.url} className={classes.detailIcon} />
              <Text className={classes.detailTitle}>{secondService?.title}</Text>
              <Text className={classes.detailContent}>{secondService?.content}</Text>
              <img src="/images/arrow.png" className={classes.arrow} />
            </Box>
          </Link>
        </Grid.Col>

        <Grid.Col className={classes.firstNews} span={12} sm={7}>
          <Link style={{ height: "100%" }} href={"/rental-service"} className={cx(classes.link, classes.rightSection)}>
            <img src={thirdService?.image?.data?.attributes?.url} alt="News" />
          </Link>
        </Grid.Col>
      </Grid>
    </Flex>
  )
}
